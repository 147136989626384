<template>
  <v-container>
    <h2 class="mb-1">Use our Promo Codes</h2>
    <!-- <p class="text-subtitle-1 font-weight-bold">
      Got questions? Call or Whatsapp {{ siteData.phone }}
    </p> -->
    <a href="https://blog.snappyexchange.com/category/promo" target="_blank">
      <v-img src="../../assets/img/promo-code.jpeg" />
    </a>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      loading: true
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      siteData: (state) => state.user.siteSettings
    })
  },
  methods: {
    doneLoading() {
      this.loading = false
    }
  }
}
</script>

<style></style>
